<template>
  <div class="game-credit-box position-relative">

    <DateFilter :with-market-id="false" :defaultMarketIds="[]" @submit="search" />

    <div v-if="dateText.start===dateText.end">ข้อมูลวันที่ <span class="text-primary">{{dateText.start}}</span></div>
    <div v-else>
      ข้อมูลรวม <span class="text-primary">{{totalDay}}</span> วัน
      ตั้งแต่วันที่ <span class="text-primary">{{dateText.start}}</span>
      <span> ถึง
        <span class="text-primary">{{dateText.end}}</span>
      </span>
    </div>

    <div class="card mb-0">
      <table class="table-game-credit table table-bordered table-pp table-hover mb-0">
        <thead>
          <tr>
            <th width="60">ลำดับ</th>
            <th width="180">รหัสอ้างอิง</th>
            <th width="120">ทำรายการโดย</th>
            <th width="160">วันที่</th>
            <th>รายละเอียด</th>
            <th width="100">จำนวน</th>
            <th width="100">คงเหลือ</th>
            <th>หมายเหตุ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id">
            <td class="text-center">{{index+1}}</td>
            <td class="text-center text-info">{{item.refCode}}</td>
            <td class="text-center">{{item.by.username}}</td>
            <td class="text-center text-secondary">{{item.date | dispDateTime("DD/MM/YYYY HH:mm:ss")}}</td>
            <td class="text-secondary">{{item.description}}</td>
            <td class="text-right">
              <span v-if="item.amounts.withdraw > 0" class="text-danger">-{{item.amounts.withdraw | amountFormat(2, '0.00')}}</span>
              <span v-else-if="item.amounts.deposit > 0" class="text-success">{{item.amounts.deposit | amountFormat(2, '0.00')}}</span>
            </td>
            <td class="text-right text-primary">{{item.amounts.after | amountFormat(2, '0.00')}}</td>
            <td class="text-center text-secondary">{{item.remarks}}</td>
          </tr>
        </tbody>
        <tbody v-if="!isLoading && !items.length">
          <tr>
            <td colspan="10" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
      </table>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EEEEEE"
      :height="80"
      :width="80"
      color="#E5881E"
      loader="dots"
    />
  </div>
</template>
<script>
import GameService from '@/services/GameService'
import DateFilter from '@/views/win-lose-report/components/DateFilter'
import cAlert from '@/helpers/alert'
import moment from '@/helpers/moment'

export default {
  name: 'GameCreditBox',
  props: ['accountId'],
  components: {
    DateFilter
  },
  data() {
    return {
      data: null,
      isLoading: false,
      filters: {
        startDate: null,
        endDate: null
      }
    }
  },
  computed: {
    dateText() {
      return {
        start: moment(this.filters.startDate).add(543, 'y').format('D MMMM YYYY'),
        end: moment(this.filters.endDate).add(543, 'y').format('D MMMM YYYY')
      }
    },
    totalDay() {
      return moment(this.filters.endDate).diff(this.filters.startDate, 'd') + 1
    },
    items() {
      return this.data?.items ?? []
    }
  },
  watch: {
    accountId(n, o) {
      console.log('accountId', n, o)
    }
  },
  methods: {
    search(filters) {
      this.filters = {
        ...this.filters,
        startDate: filters.date.start,
        endDate: filters.date.end
      }

      if(this.accountId) {
        this.getData()
      }
    },
    getData() {
      this.isLoading = true
      GameService.getMemberCreditHistory(this.filters, this.accountId).then((response)=>{
        if(response.success) {
          this.data = response.data
          this.$emit('data', response.data)
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: 'โหลดรายงานไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-size: 85%;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td, th {
        padding: 5px;
        vertical-align: top;
        font-size: 85%;
      }
    }
  }
  tfoot {
    tr {
      td, th {
        font-size: 85%;
      }
    }
  }
}
</style>
